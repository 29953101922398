<template>
  <div v-if="loading" :class="$style.preschoolTeacherDetails__loading">
    <Loading />
  </div>

  <div v-else :class="$style.preschoolTeacherDetails">
    <Title text="Детали педагога дошкольного образования" position="left" />
    <Tabs
      style="margin-bottom: 1.5rem"
      :tabs="tabs"
      :default-active-tab-id="activeTab"
      @input="(id) => (activeTab = id)"
    />

    <div :class="$style.preschoolTeacherDetails__content">
      <div v-if="activeTab === 1" :class="$style.preschoolTeacherDetails__content_wrap">
        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Фамилия*</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherAttributes.lastName) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Имя*</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherAttributes.firstName) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Отчество</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherAttributes.middleName) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Год рождения</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherAttributes.birthday) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Пол</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherAttributes.gender) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Регион*</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherAttributes.region) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Телефон*</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherDetails.phone) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Дополнительный телефон</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherDetails.reservedPhone) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Почта*</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherDetails.email) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Статус</p>
          <div>
            <span>{{
              preschoolTeacherDetails.preschoolTeacher &&
              preschoolTeacherDetails.preschoolTeacher.isVerified
                ? 'Активен'
                : 'Не активен'
            }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info">
          <p>Подтвержден методистом</p>
          <div>
            <span>{{ isVerifiedByMethodist }}</span>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 1" :class="$style.preschoolTeacherDetails__content_wrap">
        <h6 :class="$style.preschoolTeacherDetails__content_subtitle">Образование</h6>

        <div
          v-for="education in preschoolTeacherInfo.educations"
          :key="education.id"
          :class="$style.preschoolTeacherDetails__content_education"
        >
          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Вуз*</p>
            <div>
              {{ setValueInField(education.university) }}
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Город*</p>
            <div>
              {{ setValueInField(education.place) }}
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Специальность*</p>
            <div>
              {{ setValueInField(education.speciality) }}
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Виды высшего образования</p>
            <div>
              {{ setValueInField(education.type ? education.type.name : null) }}
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Педагогическое образование*</p>
            <div>
              {{ education.hasAdvancedTraining ? 'Есть' : 'Нет' }}
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Год окончания*</p>
            <div>
              {{ setValueInField(education.year) }}
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 1" :class="$style.preschoolTeacherDetails__content_wrap">
        <h6 :class="$style.preschoolTeacherDetails__content_subtitle">Добавление минут</h6>

        <div :class="$style.preschoolTeacherDetails__content_education">
          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Остаток минут</p>
            <div>
              <span>{{ setValueInField(preschoolTeacherDetails.account.minutes) }}</span>
            </div>
          </div>
        </div>

        <div v-if="isAdmin" :class="$style.preschoolTeacherDetails__content_education">
          <div :class="$style.preschoolTeacherDetails__content_input">
            <p :class="$style.preschoolTeacherDetails__content_text">Минуты</p>
            <InputNumber
              :maxlength="5"
              v-model="minutes"
              style="border-color: #e4e4e4"
              @input="(value)=> setMinutes(value, 'minutes')"
            />
          </div>
          <div style="display: flex;margin: auto 0;">
            <Button type="tertiary" @click="addMinutes">Добавить</Button>
          </div>
        </div>
      </div>
      <div v-if="activeTab === 3" :class="$style.preschoolTeacherDetails__content_wrap">
        <h6 :class="$style.preschoolTeacherDetails__content_subtitle">
          Трудовая деятельность
        </h6>

        <div
          v-for="workplace in preschoolTeacherInfo.workplaces"
          :key="workplace.id"
          :class="$style.preschoolTeacherDetails__content_workplace"
        >
          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Статус занятости*</p>
            <div>
              <span>{{
                workplace.isCurrent ? 'Текущее место работы' : 'Прошлое место работы'
              }}</span>
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Название организации*</p>
            <div>
              <span>{{ setValueInField(workplace.organization) }}</span>
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>Название должности*</p>
            <div>
              <span>{{ setValueInField(workplace.position) }}</span>
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_workplace_flex">
            <div
              :class="[
                $style.preschoolTeacherDetails__content_info,
                $style.preschoolTeacherDetails__content_workplace_date
              ]"
            >
              <p>Начало работы*</p>
              <div>
                <span>{{
                  setValueInField(moment(workplace.start).format('DD-MM-YYYY'))
                }}</span>
              </div>
            </div>

            <div
              v-if="workplace.end"
              :class="[
                $style.preschoolTeacherDetails__content_info,
                $style.preschoolTeacherDetails__content_workplace_date
              ]"
            >
              <p>Конец работы*</p>
              <div>
                <span>{{ setValueInField(moment(workplace.end).format('DD-MM-YYYY')) }}</span>
              </div>
            </div>
          </div>

          <div :class="$style.preschoolTeacherDetails__content_info">
            <p>ФИО непосредственного руководителя*</p>
            <div>
              <span>{{ setValueInField(workplace.chiefFullName) }}</span>
            </div>
          </div>

          <div
            v-if="workplace.dismissalReason"
            :class="$style.preschoolTeacherDetails__content_info"
          >
            <p>Причины ухода*</p>
            <div>
              <span>{{ setValueInField(workplace.dismissalReason) }}</span>
            </div>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 3" :class="$style.preschoolTeacherDetails__content_flex">
        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 500px">
          <p>Кол-во случаев частной индивидуальной практики с детьми по подготовке к школе*</p>
          <div>
            <span
              >{{ setValueInField(preschoolTeacherInfo.privateTeachingPracticesQuantity) }}
              из них в дистанционном режиме
              {{
                setValueInField(preschoolTeacherInfo.distancePrivateTeachingPracticesQuantity)
              }}
            </span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 600px">
          <p>
            Длительность программ, по которым педагог вёл подготовку с детьми (с ребенком)*
          </p>
          <div v-if="programsDurationsExperiences.length">
            <span
              v-for="program in programsDurationsExperiences"
              :key="program.id"
              :class="$style.preschoolTeacherDetails__content_item"
              >{{
                program.customName ? program.customName : program.programDuration.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 600px">
          <p>Cодержание программы, которое педагог чаще всего включал в подготовку к школе*</p>
          <div v-if="programsContentsExperiences.length">
            <span
              v-for="program in programsContentsExperiences"
              :key="program.id"
              :class="$style.preschoolTeacherDetails__content_item"
              >{{
                program.customName ? program.customName : program.programContent.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 620px">
          <p>
            Программа (пособие), которые педагог чаще всего использует при подготовке ребенка к
            школе*
          </p>
          <div v-if="tutorialsTypesExperiences.length">
            <span
              v-for="tutorial in tutorialsTypesExperiences"
              :key="tutorial.id"
              :class="$style.preschoolTeacherDetails__content_item"
              >{{
                tutorial.customName ? tutorial.customName : tutorial.tutorialType.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 800px">
          <p>
            Диагностическая методика, которую педагог чаще всего использует при определении
            уровня готовности ребенка к школе*
          </p>
          <div>
            <span>{{ setValueInField(preschoolTeacherInfo.diagnosticMethod) }}</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 400px">
          <p>Предпочитаемые направления подготовки к школе*</p>
          <div v-if="programsContentsPreferences.length">
            <span
              v-for="program in programsContentsPreferences"
              :key="program.id"
              :class="$style.preschoolTeacherDetails__content_item"
              >{{
                program.customName ? program.customName : program.programContent.name
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div :class="$style.preschoolTeacherDetails__content_info" style="min-width: 70%">
          <p>Контакты коллег с коротыми вы работали, кто может вас рекомендовать*</p>
          <div v-if="recommendationContacts.length">
            <span
              v-for="recommendationContact in recommendationContacts"
              :key="recommendationContact.id"
              :class="$style.preschoolTeacherDetails__content_item"
              >{{
                `ФИО*: ${setValueInField(
                  recommendationContact.fullName
                )}; Телефон: ${setValueInField(
                  recommendationContact.phone
                )}; Email*: ${setValueInField(recommendationContact.email)} `
              }}</span
            >
          </div>
          <div v-else>
            <span>Не заполнено</span>
          </div>
        </div>

        <div
          :class="[
            $style.preschoolTeacherDetails__content_info,
            $style.preschoolTeacherDetails__content_info_textarea
          ]"
        >
          <p>О себе*</p>
          <div>
            <span>{{ setValueInField(preschoolTeacherInfo.personalInfo) }}</span>
          </div>
        </div>
      </div>

      <div v-if="activeTab === 2">
        <div :class="$style.periods">
          <img src="../assets/icons/calendar-pin.svg" alt=" " class="pin" />

          <p :class="$style.subtitle">ГРАФИК РАБОТЫ</p>

          <div :class="$style.calendar">
            <Calendar :periods="periods" />
          </div>
        </div>
      </div>

      <div
        v-if="pedagogFiles.length && activeTab === 3"
        :class="$style.preschoolTeacherDetails__files"
      >
        <div
          v-for="file in pedagogFiles"
          :key="file.id"
          :class="$style.preschoolTeacherDetails__file"
        >
          <span> {{ file.name }}</span>
          <img
            src="@/assets/icons/save.svg"
            alt="download"
            @click="downloadFileFromServer(file)"
          />
        </div>
      </div>
    </div>

    <div v-if="isAdmin" :class="$style.preschoolTeacherDetails__form">
      <Button
        :class="$style.preschoolTeacherDetails__form_approve"
        type="table"
        @click="checkIsSelfEmployed"
      >
        Проверить статус самозанятости
      </Button>

      <div style="text-align: center" v-show="showIsSelfEmployed">
        {{ isSelfEmployed ? 'Самозанят' : 'Не самозанят' }}
      </div>
    </div>

    <div
      v-if="
        [employeeRoles.ADMINISTRATOR, employeeRoles.METHODIST_PRESCHOOL_TEACHER].includes(
          roleId
        )
      "
      :class="$style.preschoolTeacherDetails__form"
    >
      <Button
        type="table"
        :class="$style.preschoolTeacherDetails__form_approve"
        @click="setIsVerified"
      >
        Подтвердить
      </Button>
      <Button
        type="table"
        :class="$style.preschoolTeacherDetails__form_approve"
        @click="deleteTeacher"
      >
        Удалить
      </Button>
      <Button
        type="table"
        v-if="roleId === employeeRoles.METHODIST_PRESCHOOL_TEACHER"
        :class="$style.preschoolTeacherDetails__form_decline"
        @click="toggleIsTextAreaShown"
      >
        Отклонить
      </Button>
      <Button
        type="table"
        v-if="isAdmin"
        :class="$style.preschoolTeacherDetails__form_decline"
        @click="() => toggleIsVerifiedByAdmin()"
      >
        Отклонить
      </Button>

      <div :class="$style.preschoolTeacherDetails__form_textarea" v-show="isTextAreaShown">
        <i @click="toggleIsTextAreaShown">X</i>
        <button
          :class="$style.preschoolTeacherDetails__form_textarea_send"
          @click="() => toggleIsVerifiedByMethodist(false)"
        >
          Отправить
        </button>
        <textarea
          v-model="declineReason"
          placeholder="Опишите причину отказа"
          rows="15"
          cols="55"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from '@/plugins/axios'
import userApi from '@/api/users'
import { EmployeeRoles } from '@/application/constants'

import Title from '@/basic/Title'
import Button from '@/basic/Button'
import Loading from '@/basic/Loading'
import Tabs from '@/basic/Tabs'
import Calendar from '@/basic/Calendar'
import InputNumber from '@/basic/InputNumber.vue'

export default {
  name: 'PreschoolTeacherDetails',
  components: {
    InputNumber,
    Title,
    Button,
    Loading,
    Tabs,
    Calendar
  },
  data() {
    return {
      loading: false,
      minutes: null,
      isTextAreaShown: false,
      isSelfEmployed: false,
      showIsSelfEmployed: false,
      declineReason: '',
      tabs: [
        {
          id: 1,
          name: 'Личные данные'
        },
        {
          id: 2,
          name: 'Календарь'
        },
        {
          id: 3,
          name: 'Доп. информация'
        }
      ],
      periods: [],
      activeTab: 1,
      employeeRoles: EmployeeRoles
    }
  },
  async created() {
    try {
      this.loading = true
      await Promise.all([
        this.getPreschoolTeacher(this.pedagogId),
        this.getPedagogFiles(this.pedagogId)
      ])
      this.periods = this.preschoolTeacherDetails.preschoolTeacher.workingPeriod
    } finally {
      this.loading = false
    }
  },
  computed: {
    ...mapGetters({
      preschoolTeacherDetails: 'preschoolTeacherDetails',
      pedagogFiles: 'pedagogFiles',
      roleId: 'userRoleId',
      isAdmin: 'isAdmin'
    }),

    moment() {
      return Moment
    },

    pedagogId() {
      return this.preschoolTeacherDetails?.id ?? this.$route.params.id
    },

    preschoolTeacherAttributes() {
      return this.preschoolTeacherDetails.attributes
    },

    preschoolTeacherInfo() {
      return this.preschoolTeacherDetails.preschoolTeacher
    },

    isVerifiedByMethodist() {
      return this.preschoolTeacherInfo?.isVerifiedByMethodist
        ? 'Да'
        : `Нет; Причина отказа : ${
            this.preschoolTeacherInfo.declineByMethodistReason ?? 'Не указано'
          }` ?? null
    },

    programsDurationsExperiences() {
      return this.preschoolTeacherInfo.programsDurationsExperiences
    },

    programsContentsExperiences() {
      return this.preschoolTeacherInfo.programsContentsExperiences
    },

    tutorialsTypesExperiences() {
      return this.preschoolTeacherInfo.tutorialsTypesExperiences
    },

    programsContentsPreferences() {
      return this.preschoolTeacherInfo.programsContentsPreferences
    },

    recommendationContacts() {
      return this.preschoolTeacherInfo.recommendationContacts
    }
  },
  methods: {
    ...mapActions(['getPreschoolTeacher', 'getPedagogFiles']),
    ...mapMutations(['setPreschoolTeacherDetails']),

    async checkIsSelfEmployed() {
      try {
        const { data } = await userApi.checkIsSelfEmployedPreschool(this.pedagogId)

        if (data?.success) {
          this.isSelfEmployed = data.result.active

          this.toggleShowIsSelfEmployed()
        }
      } catch (error) {
        console.warn(error)
      }
    },
    toggleShowIsSelfEmployed() {
      this.showIsSelfEmployed = !this.showIsSelfEmployed
    },
    async toggleIsVerifiedByMethodist(value) {
      try {
        const { id } = this.$route.params
        if (value) {
          const { data } = await axios.patch(
            `${process.env.VUE_APP_API_URL}/dashboard/preschoolTeachers/methodist/${id}`,
            {
              isApprove: value
            }
          )
          const preschoolTeacherDetails = data.result.rows[0]
          this.setPreschoolTeacherDetails(preschoolTeacherDetails)
        } else {
          if (!this.declineReason) {
            alert('Укажите причину отказа')
          }
          const { data } = await axios.patch(
            `${process.env.VUE_APP_API_URL}/dashboard/preschoolTeachers/methodist/${id}`,
            {
              isApprove: value,
              text: this.declineReason
            }
          )
          const preschoolTeacherDetails = data.result.rows[0]
          this.setPreschoolTeacherDetails(preschoolTeacherDetails)
          this.toggleIsTextAreaShown()
        }
      } catch (e) {
        console.error(e)
        alert(e)
      }
    },
    async setIsVerified() {
      try {
        const { id } = this.$route.params
        const { data } = await axios.patch(
          `${process.env.VUE_APP_API_URL}/dashboard/preschoolTeachers/${id}`,
          {
            isApprove: true
          }
        )
        const preschoolTeacherDetails = data.result.rows[0]
        this.setPreschoolTeacherDetails(preschoolTeacherDetails)

        if (data?.success) {
          this.isVerified = true
        }
      } catch (error) {
        console.warn(error)
      }
    },
    async toggleIsVerifiedByAdmin() {
      try {
        const { id } = this.$route.params
        const { data } = await axios.patch(
          `${process.env.VUE_APP_API_URL}/dashboard/preschoolTeachers/${id}`,
          {
            isApprove: false
          }
        )
        const preschoolTeacherDetails = data.result.rows[0]
        this.setPreschoolTeacherDetails(preschoolTeacherDetails)

        if (data?.success) {
          this.isVerified = true
        }
      } catch (error) {
        console.warn(error)
      }
    },
    setValueInField(value) {
      return value ? value : 'Не заполнено'
    },
    setMinutes(value, type) {
      if (value.length === 0) {
        this[type] = null
      }
    },
    async addMinutes() {
      try {
        this.loading = true
        const data = { userId: this.preschoolTeacherAttributes.userId }
        if (this.minutes === '0') return
        if (this.minutes) {
          data.minutes = +this.minutes
        }
        const { success } = await userApi.addMinutes(data)
        if (success) {
          this.minutes = null
          await this.getPreschoolTeacher(this.preschoolTeacherDetails.id)
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка добавление минут преподавателю на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    async deleteTeacher () {
      try {
        this.loading = true
        const { success } = await userApi.deleteUser({ userId: this.preschoolTeacherAttributes.userId })
        if (success) {
          await this.$router.push('/preschoolTeachers')
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: success
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка на сервере при удалении преподавателя!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    toggleIsTextAreaShown() {
      this.isTextAreaShown = !this.isTextAreaShown
    },

    async downloadFileFromServer(data) {
      try {
        this.loading = true

        const resp = await userApi.downloadPedagogFile(data.id)

        if (resp?.status === 200) {
          let a = document.createElement('a')
          let file = new Blob([resp.data], { type: 'application/json' })
          a.href = URL.createObjectURL(file)
          a.download = data.name
          a.click()
        }
      } catch (error) {
        console.warn(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.preschoolTeacherDetails {
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
  }

  &__content {
    &_flex {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    &_input {
      margin: 0 0.625rem 0.938rem 0
    }
    &_title {
      margin-bottom: 10px;

      h2 {
        font-size: 20px;
        font-weight: 500;
      }
    }

    &_subtitle {
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 500;
    }

    &_education {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 0 0 1rem;
    }

    &_workplace {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      margin: 0 0 1rem;

      &_date {
        min-width: auto !important;
        width: 7rem !important;
      }

      &_flex {
        display: flex;
      }
    }

    &_wrap {
      display: flex;
      flex-wrap: wrap;
    }

    &_info {
      position: relative;
      min-width: 275px;
      width: auto;
      margin-right: 10px;
      margin-bottom: 15px;

      p {
        position: absolute;
        top: -8px;
        left: 5px;
        background: $white;
        font-size: 12px;
        color: $gray;
      }

      div {
        border: 1px solid $medium-gray;
        padding: 5px;
      }

      &_textarea {
        div {
          height: 300px;
          width: 400px;
          overflow-wrap: break-word;
        }
      }
    }

    &_item {
      display: block;
      padding: 0.375rem;
      border-bottom: 1px solid $medium-gray;

      span {
        padding: 0.125rem 0;
      }

      &:last-child {
        border: none;
      }
    }
  }

  &__files {
    width: 70%;
    border: 1px solid $medium-gray;
    padding: 0.375rem;
    margin: 0 0 1rem;
  }

  &__file {
    display: flex;
    justify-content: space-between;
    padding: 0.375rem;
    border-bottom: 1px solid $medium-gray;

    &:last-child {
      border: none;
    }

    img {
      cursor: pointer;
    }
  }

  &__form {
    max-width: 433px;
    margin-left: auto;

    &_approve {
      margin-bottom: 15px;
    }

    &_textarea {
      margin-top: 15px;
      position: relative;

      textarea {
        border: 1px solid black;
        resize: none;
        padding-left: 10px;
        padding-top: 5px;
      }
      &_send {
        position: absolute;
        bottom: 15px;
        right: 10px;
        cursor: pointer;
        background: #f1e6e6;
        padding: 5px 15px;
        color: #000;
        border-radius: 15px;
      }

      i {
        position: absolute;
        top: 5px;
        right: 10px;
        cursor: pointer;
        padding: 5px;
        opacity: 0.7;
        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
.periods {
  width: 25.1875rem;
  background-color: #f8f8f8;
  border-radius: 0.625rem;
  padding: 1.25rem;
  padding-bottom: 3.375rem;
  position: relative;

  .pin {
    width: 1.5rem;
    height: 3.4375rem;
    position: absolute;
    right: 1.75rem;
    top: -1.4375rem;
  }
  .calendar {
    margin-top: 3.25rem;
  }
}
</style>
